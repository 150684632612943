// eslint-disable-next-line import/prefer-default-export
export const ptInfoSchema = [{
  component: 'h3',
  children: 'Patient Information',
  class: 'tw-text-2xl tw-mb-4',
},
{
  component: 'div',
  class: 'tw-grid tw-grid-cols-6 tw-gap-6',
  children: [{
    type: 'text',
    label: 'Surname',
    name: 'lastName',
  },
  {
    type: 'text',
    label: 'Given Name',
    name: 'firstName',
  },
  {
    type: 'text',
    label: 'Gender',
    name: 'gender',
  },
  ],
},
{
  component: 'div',
  class: 'tw-grid tw-grid-cols-6 tw-gap-6',
  children: [{
    type: 'text',
    label: 'Date of Birth',
    name: 'dob',
  },
  {
    type: 'text',
    label: 'OHIP Number',
    name: 'ohip',
  },
  {
    type: 'text',
    label: 'Version Code',
    name: 'versionCode',
  },
  ],
},
{
  component: 'div',
  class: 'tw-grid tw-grid-cols-6 tw-gap-6',
  children: [{
    type: 'text',
    label: 'Address',
    name: 'ptAddress',
  },
  {
    type: 'text',
    label: 'City',
    name: 'ptCity',
  },
  {
    type: 'text',
    label: 'Postal Code',
    name: 'ptPostalCode',
  },
  ],
},
{
  component: 'div',
  class: 'tw-grid tw-grid-cols-6 tw-gap-6',
  children: [{
    type: 'text',
    label: 'Telephone',
    name: 'ptTel',
  },
  {
    type: 'text',
    label: 'Other Relevant Information',
    name: 'Other_Relevant_Information',
  },
  ],
},
]
export const drInfoSchema = [{
  component: 'h3',
  children: 'Doctor Information',
  class: 'tw-text-2xl tw-mb-4',
},
{
  component: 'div',
  class: 'tw-grid tw-grid-cols-6 tw-gap-6',
  children: [{
    type: 'text',
    label: 'Name',
    name: 'drName',
  },
  {
    type: 'text',
    label: 'ID Number',
    name: 'cpso',
  },
  {
    type: 'text',
    label: 'Prescriber’s One-Mail Email Address (If available from presecriber)',
    name: 'drEmail',
  },
  ],
},
{
  component: 'div',
  class: 'tw-grid tw-grid-cols-6 tw-gap-6',
  children: [{
    type: 'text',
    label: 'Facsimile',
    name: 'drFax',
  },
  {
    type: 'text',
    label: 'Office Telephone',
    name: 'drTel',
  },
  {
    type: 'text',
    label: 'Date/Time of Transmission to Prescriber:',
    name: 'drFaxDateTime',
  },
  ],
},
]
export const drugRelatedProblemSchema = [{
  component: 'h3',
  children: 'Please check one of the following:',
  class: 'tw-text-2xl tw-mb-4',
},
{
  component: 'div',
  class: 'tw-grid tw-grid-cols-6 tw-gap-6',
  children: [{
    type: 'radio',

    name: 'drpCategory',
    options: {
      TherapeuticDuplication: 'Therapeutic duplication drug may not be necessary',
      needsAdditionalDrugTherapy: 'Patient needs additional drug therapy',
      subOptimalResponse: 'Drug is not working as well as needed (sub-optimal response)',
      doseTooLow: 'Dose is too low',
      adverseDrugReaction: 'Adverse drug reaction due to allergy or conflict with another medication or food',
      dangerousHighDose: 'Dangerously high dose prescribed or patient taking too much medication (either accidentally or deliberately',
      nonCompliance: 'Non-compliance (refusing drug or not taking it properly',
      falseoralteredRx: 'False or altered prescription has been confirmed',
    },

    //     'outer-class': ['tw-col-span-6 sm:tw-col-span-3'],

    // 'input-label-class': ['tw-ml-3 tw-block tw-text-sm tw-font-medium tw-text-gray-700'],

    // 'input-class': ['focus:tw-ring-indigo-500 tw-h-4 tw-w-4 tw-text-indigo-600 tw-border-gray-300'],
  },
  {
    type: 'radio',
    label: 'Level of Urgency',
    name: 'urgency',
    options: {
      low: 'Low',
      med: 'Medium',
      high: 'High',
    },

    //    'outer-class': ['tw-col-span-6 sm:tw-col-span-3'],
  },
  ],
},
{
  component: 'div',
  class: 'tw-mt-1',
  children: [{
    type: 'textarea',
    label: 'Pharmacist: Please provide commentary on DRP where appropriate',
    name: 'rphComments',
    rows: '6',

    // 'outer-class': [
    //   'tw-shadow-sm focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full sm:tw-text-sm tw-border tw-border-gray-700 tw-rounded-md',
    // ],
  }],
},
{
  component: 'div',
  class: 'tw-mt-1',
  children: [{
    type: 'textarea',
    label: 'Pharmacist Recommendation on Current DRP',
    name: 'pharmacistRecommendation',
    rows: '6',

    // 'outer-class': [
    //   'tw-shadow-sm focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full sm:tw-text-sm tw-border tw-border-gray-700 tw-rounded-md',
    // ],
  }],
},
]
export const rxInfoSchema = [{
  component: 'h3',
  children: 'Original Prescription',
  class: 'tw-text-2xl tw-mb-4',
},
{
  component: 'div',
  class: 'tw-grid tw-grid-cols-6 tw-gap-6',
  children: [{
    type: 'text',
    label: 'Original Rx Number',
    name: 'originalRxnumber',
  },
  {
    type: 'text',
    label: 'Date of Transaction',
    name: 'dateOfTransaction',
  },
  {
    type: 'text',
    label: 'Date/Time of Transmission to Prescriber',
    name: 'drFaxDateTime',
  },
  ],
},
{
  component: 'div',
  class: 'tw-mt-1',
  children: [{
    type: 'textarea',
    label: 'Original prescription',
    name: 'rxDetails',
    rows: '6',
    'input-class': ['tw-border-black'],

    // 'outer-class': [
    //   'tw-shadow-sm focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-mt-1 tw-block tw-w-full sm:tw-text-sm tw-border tw-border-gray-700 tw-rounded-md',
    // ],
  }],
},
]

export
const dataIntercomTarget = {
  patientSearch: 'appBarPatientSearch',
  appBarPatientSearchButton: 'appBarPatientSearchButton',
  appBarPatientSearchDialog: 'appBarPatientSearchDialog',
  patientSearchModalLastName: 'patientSearchModalLastName',
  patientSearchModalFirstName: 'patientSearchModalFirstName',
  patientSearchModalPAtientSelect: 'patientSearchModalPAtientSelect',
  patientformsFormCardOpinion: 'patientformsFormCardOpinion',
  opinionPrescriptionList: 'opinionPrescriptionList',
  opinionDoctorList: 'opinionDoctorList',
  opinionButtonNext: 'opinionButtonNext',
  opinionRadioDtpPatientNEedsAdditionalDrugTherapy: 'opinionRadioDtpPatientNEedsAdditionalDrugTherapy',
  opinionRadioUrgencyMedium: 'opinionRadioUrgencyMedium',
  OpinionTextCommentary: 'OpinionTextCommentary',
  OpinionTextRecommendation: 'OpinionTextRecommendation',
  opinionButtonNext2: 'opinionButtonNext2',
  opinionSigningPad: 'opinionSigningPad',
  opinionButtonSubmit: 'opinionButtonSubmit',
}
