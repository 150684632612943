<template>
  <div>
    <v-card
      color="pglightblue"
    >
      <v-card-title class="justify-left tw-text-white">
        <p class="tw-text-white tw-font-bold">
          Pharmacuetical Opinion -
        </p>
        <p
          v-if="patientProfile.PatientID"
          class="tw-justify-center tw-content-center tw-text-white tw-text-base tw-font-semibold"
        >
          {{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}
        </p>
      </v-card-title>
      <!-- <div
        :key="componentTopCardKey"
        class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4"
      >
        <div v-if="patientProfile.PatientID">
          <v-card-text class="text-center tw-text-white ">
            <p class="tw-text-white tw-text-base tw-font-semibold ma-0 ">
              {{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}
            </p>
            <p class="tw-m-0 tw-text-white ma-0">
              Gender: {{ patientProfile['GENDER'] }} || DOB: {{ this.$moment(this.patientProfile['DATE OF BIRTH']).format('YYYY-MM-DD') }}
            </p>
          </v-card-text>
        </div>
        <div>
          <v-card-text
            v-if="selectedMedication.length > 0"
            class="text-center tw-text-white"
          >
            <p class="tw-text-white tw-text-base ma-0 tw-font-semibold">
              {{ patientProfile.selectedMedication[0].BrandName }} {{ patientProfile.selectedMedication[0].RxStrength }}
            </p>
            <p class="tw-m-0 tw-text-white ma-0">
              {{ patientProfile.selectedMedication[0].RxNumber }}||Date: {{ this.$moment(this.patientProfile.selectedMedication[0].RxDate).format('YYYY-MM-DD') }}||DIN: {{ patientProfile.selectedMedication[0].DIN }}
            </p>
          </v-card-text>
        </div>
        <div>
          <v-card-text
            v-if="selectedDoctor.length > 0"
            class="text-center tw-text-white"
          >
            <p class="tw-m-0 tw-text-white tw-text-base ma-0 tw-font-semibold">
              Dr. {{ selectedDoctor[0].DoctorFirstName }} {{ selectedDoctor[0].DoctorLastName }}
            </p>
            <p class="tw-m-0 tw-text-white ma-0">
              Fax: {{ selectedDoctor[0].DoctorFax }} || Tel: {{ selectedDoctor[0].DoctorPhone }}
            </p>
          </v-card-text>
        </div>
      </div> -->
    </v-card>

    <v-stepper
      v-model="activeStep"
      non-linear
      class="custom-header"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">01</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Medication</span>
              <span class="text--secondary text-xs">Select Med and Doctor</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="2"
          :complete="activeStep > 2"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">02</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Details</span>
              <span class="text--secondary text-xs">Lets Document the Opinion</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3">
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">03</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Form Signoff</span>
              <span class="text--secondary text-xs">Form Submission</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card :key="componentKeyMedList">
            <medication-selection
              :selected="selectedMedication"
              @medicationupdatelist="selected"
            >
            </medication-selection>
          </v-card>
          <template>
            <v-card data-intercom-target="DoctorList">
              <v-card-title>
                <p class="tw-text-blue-900 tw-text-2xl tw-font-bold dark:tw-text-white dark:tw-text-opacity-50">
                  Please select a doctor from the list below:
                </p>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                v-model="selectedDoctor"
                :search="search"
                show-select
                hide-default-footer
                :items="doctorList"
                item-key="DoctorSelectId"
                :headers="headers"
                class=""
                :single-select="singleSelect"
                fixed-header
                @click:row="rowClick"
              >
                <template slot="no-data">
                  <tr>
                    <td
                      colspan="6"
                      class="pa-4"
                    >
                      No doctors to select from
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </template>
        </v-stepper-content>

        <v-stepper-content step="2">
          <div class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-3">
            <div class="tw-mt-5 md:tw-t-0 md:tw-col-span-2">
              <div class="tw-shadow tw-overflow-hidden sm:tw-rounded-md">
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                  <FormulateForm
                    :key="1"
                    v-model="prePopulate"
                    data-intercom-target="opinionRadioDtpPatientNEedsAdditionalDrugTherapy"
                    :schema="drugRelatedProblemSchema"
                  />
                </div>
              </div>
            </div>
            <div class="tw-mt-5">
              <div class="d-flex justify-center align-center">
                <div class="tw-w-11/12 tw-card tw-shadow-2xl lg:tw-card-side tw-bg-pglightblue tw-text-primary-content">
                  <div class="tw-card-body">
                    <div class="tw-grid tw-grid-cols-1 tw-gap-4 tw-justify-items-start tw-items-start">
                      <p class="tw-font-black">
                        Patient Details:
                      </p>
                    </div>
                    <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-justify-items-start tw-items-start">
                      <div>{{ this.prePopulate.lastName }} {{ this.prePopulate.firstName }}</div>
                      <div>Gender: {{ this.prePopulate.gender }}</div>
                      <div>DOB: {{ this.prePopulate.dob }}</div>
                      <div>OHIP Number: {{ this.prePopulate.ohip }}</div>
                      <div>Telephone: {{ this.prePopulate.ptTel }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tw-pt-6 d-flex justify-center align-center">
                <div class="tw-w-11/12 tw-card tw-shadow-2xl lg:tw-card-side tw-bg-pglightblue tw-text-primary-content">
                  <div class="tw-card-body">
                    <div class="tw-grid tw-grid-cols-1 tw-gap-4 tw-justify-items-start tw-items-start">
                      <p class="tw-font-black">
                        Prescription Details:
                      </p>
                    </div>
                    <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-justify-items-start tw-items-start">
                      <div>Dr. {{ this.prePopulate.FirstName }} {{ this.prePopulate.LastName }}</div>
                      <div>CPSO: {{ this.prePopulate.CPSO }}</div>
                      <div>Tel: {{ this.prePopulate.DoctorPhone }}</div>
                      <div>Fax: {{ this.prePopulate.DoctorFax }}</div>
                      <div>Rx #: {{ this.prePopulate.RxNumber }}</div>
                      <div>Rx Date: {{ this.prePopulate.RxDate }}</div>
                    </div>
                    <div class="tw-grid tw-pt-6 tw-grid-cols-1 tw-gap-4 tw-justify-items-start tw-items-start">
                      <p class="tw-font-black">
                        Medication Details:
                      </p>
                    </div>
                    <div class="tw-grid tw-grid-cols-1 tw-justify-items-start tw-items-start">
                      <div>{{ this.prePopulate.BrandName }} ({{ this.prePopulate.GenericName }}) {{ this.prePopulate.RxStrength }} {{ this.prePopulate.Form }}</div>
                      <p class="tw-font-black tw-pt-3">
                        Instructions:
                      </p>
                      <div>
                        {{ this.prePopulate.SIGFull }}
                      </div>
                    </div>
                    <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-justify-items-start tw-items-start tw-pt-6">
                      <div>Quantity: {{ this.prePopulate.QtyDispense }}</div>
                      <div>Days Supply: {{ this.prePopulate.Days }}</div>
                      <div>Total Quantity Authorized: {{ this.prePopulate.TotalAuthorized }}</div>
                      <div>Quantity Remaining: {{ this.prePopulate.QtyRemain }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <v-expansion-panels
            v-model="midpanel"
            multiple
          >
            <v-expansion-panel
              v-for="item in schemas"
              :key="item.id"
            >
              <v-expansion-panel-header>
                {{ item.name }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="tw-mt-5 md:tw-t-0 md:tw-col-span-2">
                  <div class="tw-shadow tw-overflow-hidden sm:tw-rounded-md">
                    <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                      <FormulateForm
                        :key="item.id"
                        v-model="prePopulate"
                        :schema="item.schema"
                      />
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels> -->
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-expansion-panels v-model="finalpanel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Pharmacist Signature
                  <v-icon
                    v-if="prePopulate.PharmacistSignature === ''"
                    color="warning"
                    medium
                  >
                    {{ icons.mdiAlert }}
                  </v-icon></span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <pg-sig-input
                  :key="signatureCompKey"
                  @save="saveSignature"
                  @updatePharmacist="updatePharmacist"
                >
                </pg-sig-input>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Pharmacist Information: {{ patientProfile['rphfirstname'] }} </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                  <v-row
                    wrap
                    ml-4
                  >
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="12"
                      md="4"
                    >
                      <pharmacist-search :key="refresh"></pharmacist-search>
                      <v-btn
                        id="v-step-5"
                        color="primary"
                        primary
                        class="my-3"
                        @click.native="openPharmacistOverlay"
                      >
                        Select a Pharmacist
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="patientProfile['rphfirstname']"
                        label="Pharmacist Name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="patientProfile['ocp']"
                        label="OCP#"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-stepper-content>
      </v-stepper-items>
      <div class="d-flex justify-center align-center">
        <v-card-actions>
          <pg-form-buttons
            v-if="this.activeStep >= 1 && this.selectedMedication.length > 0 && this.selectedDoctor.length > 0"
            :step="activeStep"
            :final-step="3"
            @next="next"
            @previous="previous"
          ></pg-form-buttons>
          <!-- <v-col>
            <v-btn
              v-show="activeStep === 1 && this.selectedMedication.length > 0 && this.selectedDoctor.length > 0"
              color="success"
              large
              data-intercom-target="opinionButtonNext"
              @click="nextstepone"
            >
              Next
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 2"
              color="warning"
              large
              @click="activeStep = 1"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 3"
              color="warning"
              large
              @click="activeStep = 2"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 2"
              color="success"
              large
              data-intercom-target="opinionButtonNext2"
              @click="nextstep"
            >
              Next
            </v-btn>
          </v-col> -->
          <v-col>
            <pg-submit
              v-show="activeStep === 3"
              id="v-step-7"
              form-type="pharmaOpinion"
              base-filename="pharmaOpinion"
              type-of-medscheck="typeOfMedscheck"
              :patient-data-decorator="ProcessMeds"
              :patient-data="prePopulate"
              data-intercom-target="opinionButtonSubmit"
              @saveInfo="savePdfInfo($event)"
            >
              Submit
            </pg-submit>
          </v-col>
        </v-card-actions>
      </div>
    </v-stepper>
    <v-dialog
      v-model="medSelectionDialog"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-title class="tw-my-2 tw-justify-center">
          This form only allows 1 drug to be selected, select a drug from your selection below:
        </v-card-title>
        <v-card-text>
          <ul class="tw-flex tw-flex-col tw-space-y-4">
            <v-chip
              v-for="drug in this.$store.state.Patient.selectedMedications"
              :key="drug.DIN"
              class="tw-w-1/2 tw-m-auto "
              @click="selectFromList([drug])"
            >
              <li>
                {{ drug.GenericName }} - {{ drug.BrandName }} - DIN:{{ drug.DIN }}
              </li>
            </v-chip>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="loadingPatient"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3">
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mdiAlert, mdiCheckboxMarkedCircleOutline } from '@mdi/js'
import PharmacistSearch from '@/components/common/ui/Form/PharmacistSearch/PharmacistSearch.vue'
import MedicationsSelection from '@/components/phoxTables/drug/singleDrug.vue'
import submitPdf from '@/components/common/ui/Form/Submit/ProcessPDF.vue'
import serverFormProcess from '@/views/Medscheck/Utils/processFormDataMedicationSelection'
import { stateService } from '@/render/api/zarya'
import {
  ptInfoSchema,
  drInfoSchema,
  drugRelatedProblemSchema,
  rxInfoSchema,
  dataIntercomTarget,
} from '@/assets/formSchema/pharmaOpinion'
import pgSigInput from '@/components/common/ui/Form/pgSigInput/pgSigInput.vue'
import pgFormButtons from '@/views/patient/components/pg-form-buttons.vue'

export default {
  name: 'PharmaceuticalOpinion',
  components: {
    'medication-selection': MedicationsSelection,
    'pg-submit': submitPdf,
    'pharmacist-search': PharmacistSearch,
    'pg-sig-input': pgSigInput,
    'pg-form-buttons': pgFormButtons,
  },

  data() {
    this.$store.dispatch('setFormClass', 'pharmaOpinion')

    return {
      medSelectionDialog: false,
      dataIntercomTarget,
      saveState: 'Save Progress',
      refresh: true,
      dateOfTransaction: '',
      dateTimeofTransaction: '',
      rxDetails: '',
      finalpanel: 0,
      midpanel: [0],
      signatureCompKey: 0,
      refreshFormulateForm: 0,
      loadingPatient: false,
      componentKeyMedList: 0,
      singleSelect: true,
      componentTopCardKey: 0,
      selectedDoctor: [],
      prePopulate: {},
      DoctorFirstName: '',
      search: '',
      doctorList: [],
      selectedMedication: [],
      icons: { mdiAlert, mdiCheckboxMarkedCircleOutline },
      activeStep: 1,
      initial: '',
      patientProfile: {},
      ptInfoSchema,
      drInfoSchema,
      drugRelatedProblemSchema,
      rxInfoSchema,
      schemas: [],
      headers: [
        {
          text: 'Last Name',
          class: 'black--text font-weight-bold subtitle-1',
          align: 'left',
          value: 'DoctorLastName',
          sortable: false,
        },
        {
          text: 'First Name',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorFirstName',
          sortable: false,
        },
        {
          text: 'CPSO',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'CPSO',
          sortable: false,
        },
        {
          text: 'Address',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorAddress',
          sortable: false,
        },
        {
          text: 'Phone',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorPhone',
          sortable: false,
        },
        {
          text: 'Fax',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorFax',
          sortable: false,
        },
        {
          text: 'Prescriptions in Last Year',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'RXperProvider',
          sortable: false,
        },
      ],
      headersCard: [
        {
          text: 'Generic Name',
          align: 'left',
          sortable: false,
          value: 'GenericName',
        },
        {
          text: 'Strength',
          align: 'left',
          sortable: false,
          value: 'RxStrength',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['FormClass', 'PatientProfile', 'PatientMedications', 'PharmacyProfile', 'UserProfile']),
    ...mapState(['Patient']),
  },
  mounted() {
    this.preloadForm()
    this.searchDoctor()
    this.refreshFormulateForm += 1
    this.saveDateSubmitted()
    this.currentDate()

    // FIXME: SHould be single select in datatable
    if (this.$store.state.Patient.selectedMedications.length > 1) {
      this.medSelectionDialog = true
    }
    if (this.$store.state.Patient.selectedMedications.length === 1) {
      this.selectedMedication = this.$store.state.Patient.selectedMedications

      // this.activeStep = 2
    }
    this.$root.$on('Refresh_Table', patientID => {
      this.currentDate()
      this.saveDateSubmitted()
      this.searchDoctor()
      this.selectedPatient()
      this.preloadForm()
      this.refreshFormulateForm += 1
      this.step = '1'
      this.finalpanel = 0
      this.midpanel = [0]
    })
  },
  methods: {
    selectFromList(med) {
      this.selected(med)
      this.componentKeyMedList++
      this.medSelectionDialog = false
    },
    previous() {
      this.activeStep--
      if (this.activeStep === 2) {
        this.patientProfile.rphfirstname = `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`
        this.patientProfile.ocp = `${this.$store.state.User.user.ocp}`
        this.signatureCompKey++
      }
    },
    next() {
      this.activeStep++
      if (this.activeStep === 2) {
        this.populateWithPatientData()
        this.medicationsForPDF(this.patientData)
      }
    },
    saveSignature({ pharmacistSig, signatureDate }) {
      this.prePopulate.PharmacistSignature = pharmacistSig
      this.prePopulate.rphsignature = pharmacistSig
      this.prePopulate.DATE = signatureDate
    },
    updatePharmacist({ pharmacistName, ocp }) {
      this.prePopulate.pharmacistName = pharmacistName
      this.prePopulate.rphfirstname = pharmacistName
      this.prePopulate.ocp = ocp
      this.patientProfile.rphfirstname = pharmacistName
    },

    rowClick(item, row) {
      row.select(true)
    },
    currentDate() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      this.patientProfile.DATE = date
      this.refresh += 1

      return date
    },
    selected(selectedMedication) {
      this.selectedMedication = selectedMedication
      this.$store.dispatch('setSelectedPatientMedications', selectedMedication)
      this.patientProfile = {
        ...this.patientProfile,
        rphfirstname: `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`,
        ocp: this.$store.state.User.user.ocp,
        selectedMedication: this.selectedMedication,
        originalRxnumber: this.selectedMedication.length ? this.selectedMedication[0].RxNumber : '',
      }
    },
    async searchDoctor() {
      this.doctorList =
        (await this.$root.$system.DBAdapterConn.getSearchByDoctor(
          this.PatientProfile.PatientID,
          this.drFirstName,
          this.drLastName,
          this.cpso,
        )) || []
    },
    async populateWithPatientData() {
      this.prePopulate = {
        ...this.patientProfile,
        lastName: this.PatientProfile['LAST NAME'],
        firstName: this.PatientProfile['FIRST NAME'],
        dob: this.$moment(this.PatientProfile['DATE OF BIRTH']).format('YYYY-MM-DD'),
        'dob[year]': this.$moment(this.PatientProfile['DATE OF BIRTH']).format('YYYY'),
        'dob[month]': this.$moment(this.PatientProfile['DATE OF BIRTH']).format('MM'),
        'dob[day]': this.$moment(this.PatientProfile['DATE OF BIRTH']).format('DD'),
        ohip: this.PatientProfile['HEALTH CARD NUMBER'],
        versionCode: '',
        gender: this.PatientProfile.GENDER,
        ptAddress: `${this.PatientProfile['STREET NUMBER']} ${this.PatientProfile['STREET NAME']}`,
        ptCity: this.PatientProfile['CITY/TOWN'],
        ptPostalCode: this.PatientProfile['POSTAL CODE'],
        ptTel: this.PatientProfile['TELEPHONE NUMBER'] || this.PatientProfile['CELLPHONE NUMBER'],
        pharmacyName: this.PatientProfile['PHARMACY NAME'],
        pharmacyStreetAdd: `${this.PatientProfile['PHARMACY UNIT NUMBER']}-${this.PatientProfile['PHARMACY STREET NUMBER']} ${this.PatientProfile['PHARMACY STREET NAME']}, ${this.PatientProfile['PHARMACY CITY']}`,
        pharmacyTelephoneNumber: this.PatientProfile['PHARMACY TELEPHONE NUMBER'],
        pharmacyFaxNumber: this.PatientProfile['PHARMACY FAX NUMBER'],
        pharmacyProvince: this.PatientProfile['PHARMACY PROVINCE'],
        pharmacyPostal: this.PatientProfile['POSTAL CODE'],
        pharmacistName: `${this.UserProfile.firstName} ${this.UserProfile.lastName}`,
        ocp: this.UserProfile.ocp,
        patientForm: 'yes',
        needsAdditionalDrugTherapy: 'On',
        pharmacyID: this.$store.getters.PharmacyProfile._id,
        userID: this.$store.getters.UserProfile._id,
        pharmacyFullAdd: `${this.$store.state.User.pharmacy.address.streetNumber} ${this.$store.state.User.pharmacy.address.streetName}, ${this.$store.state.User.pharmacy.address.city} ${this.$store.state.User.pharmacy.address.state} ${this.$store.state.User.pharmacy.address.code}`,
        patientID: this.$store.getters.PatientProfile.PatientID,
        mfax: '16473610914@send.mfax.io',
        dateOfTransaction: this.dateOfTransaction,
        drFaxDateTime: this.dateTimeofTransaction,
        rxDetails: `Original Prescription ${this.selectedMedication[0].BrandName} ${this.selectedMedication[0].RxStrength}`,
        drName: `${this.selectedDoctor[0].DoctorFirstName} ${this.selectedDoctor[0].DoctorLastName}`,
        drFax: this.selectedDoctor[0].DoctorFax,
        cpso: this.selectedDoctor[0].CPSO,
        drTel: this.selectedDoctor[0].DoctorPhone,
        PharmacistSignature: this.prePopulate.PharmacistSignature,
        RxNumber: this.selectedMedication[0].RxNumber,
        LastName: this.selectedMedication[0].LastName,
        FirstName: this.selectedMedication[0].FirstName,
        CPSO: this.selectedMedication[0].CPSO,
        DoctorPhone: this.selectedMedication[0].DoctorPhone,
        DoctorFax: this.selectedMedication[0].DoctorFax,
        RxDate: this.$moment(this.selectedMedication[0].RxDate).format('YYYY-MM-DD'),
        DIN: this.selectedMedication[0].DIN,
        GenericName: this.selectedMedication[0].GenericName,
        RxStrength: this.selectedMedication[0].RxStrength,
        Form: this.selectedMedication[0].Form,
        Schedule: this.selectedMedication[0].Schedule,
        BrandName: this.selectedMedication[0].BrandName,
        RxQtyDispense: this.selectedMedication[0].RxQtyDispense,
        QtyRemain: this.selectedMedication[0].QtyRemain,
        SIGFull: this.selectedMedication[0].SIGFull,
        QtyDispense: this.selectedMedication[0].QtyDispense,
        Days: this.selectedMedication[0].Days,
        TotalAuthorized: this.selectedMedication[0].TotalAuthorized,
      }
      this.schemas = [
        {
          id: 1,
          schema: drugRelatedProblemSchema,
          name: 'Categorization of the Drug-Related Problem (DRP)',
        },
        {
          id: 2,
          schema: ptInfoSchema,
          name: `Patient Information: ${this.PatientProfile['FIRST NAME']} ${this.PatientProfile['LAST NAME']}`,
        },
        {
          id: 3,
          schema: drInfoSchema,
          name: `Doctor Information: ${this.selectedDoctor[0].DoctorFirstName} ${this.selectedDoctor[0].DoctorLastName}`,
        },
        {
          id: 4,
          schema: rxInfoSchema,
          name: `Original Prescription ${this.selectedMedication[0].BrandName} ${this.selectedMedication[0].RxStrength}`,
        },
      ]
      this.loadingPatient = false
    },
    preloadForm() {
      this.patientData = {
        ...this.$store.state.Patient.data,
        PharmacistSignature: '',
      }
      this.medicationsList = this.$store.state.Patient.medications
      this.patientProfile = {
        ...this.$store.state.Patient.data,
      }
    },
    selectedPatient() {
      this.prePopulate = {}
      this.activeStep = 1
      this.selectedMedication = []
      this.componentTopCardKey += 1
      this.componentKeyMedList += 1
    },
    savePdfInfo(data) {
      this.prePopulate.pdfRef = {
        ...data,
      }
      this.updateStateStatus()
    },
    saveDateSubmitted() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`
      this.patientProfile.dateSubmitted = dateTime
      this.dateOfTransaction = date
      this.dateTimeofTransaction = dateTime
    },
    async updateStateStatus() {
      this.saveDateSubmitted()
      let res
      const data = {
        formType: this.$store.state.Patient.FORM_CLASS,
        state: this.prePopulate,
        patientID: this.patientProfile.PatientID,
        userID: this.UserProfile._id,
        pharmacyID: this.PharmacyProfile._id,
      }
      this.$router.push({
        name: 'dashboard-document',
        query: {
          tab: 2,
        },
      })
      const token = await this.$auth.getTokenSilently()
      try {
        if (!this.patientProfile.stateId) {
          // create it and make its status complete (this is in case they complete a medscheck and it doesnt have a stateid already -- not working )
          // eslint-disable-next-line no-undef
          res = await stateService.createState(token, {
            ...data,
          })
          this.patientProfile.stateId = res.state._id
        }
        res = await stateService.updateState(token, this.patientProfile.stateId, {
          ...data,
          status: 'complete',
        })

        return res
      } catch (err) {
        console.log(err)
      }
    },
    nextstepone() {
      this.populateWithPatientData()
      this.medicationsForPDF(this.patientData)
      this.activeStep = 2
    },
    nextstep() {
      this.activeStep = 3
    },
    ProcessMeds(patientData) {
      return serverFormProcess(this.selectedMedication, this.medicationsForPDF(patientData))
    },
    medicationsForPDF(patientData) {
      this.selectedMedication.forEach((med, idx) => {
        patientData = {
          ...patientData,
          [`rxNumbers${idx + 1}`]: `${med['RX NUMBER'] || med.RxNumber} - ${med['GENERIC NAME'] || med.GenericName} - ${
            med.STRENGTH || med.RxStrength
          } - Qty: ${med.RxQtyDispense} - Qty Auth:${med.TotalAuthorized}`,
        }
      })
      this.rxDetails = patientData

      return patientData
    },
    openPharmacistOverlay() {
      window.searchPharmacist.open(({ PharmID, UserName }) => {
        this.patientProfile = Object.assign(this.patientProfile, {
          rphfirstname: UserName,
          ocp: PharmID,
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#patientformpanel {
  div.v-card__title.justify-left {
    color: rgb(248, 248, 248) !important;
  }
  div.row {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(1) div h2 {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(2) div h2 {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(3) div h2 {
    color: rgb(248, 248, 248) !important;
  }
}

#headersTable {
  .theme--dark.v-data-table {
    background-color: #95a6df80 !important;
    color: #ffffff !important;
  }
  .theme--dark.v-data-table td {
    color: rgb(255 255 255) !important;
  }
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
  justify-content: center !important;
  align-content: center !important;
}
.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
.Signaturecontainer {
  width: '100%';
  padding: 8px 16px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
}
#noPadding {
  padding: 0;
}
.fixedElement {
  padding: 1% 2%;
  width: 100%;
  z-index: 20;
}
#buttons {
  margin: 1%;
}
.centerButtons {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}
.centerButtons button {
  margin: 0 2%;
}
iframe {
  width: 1px;
  min-width: 100%;
}
</style>
